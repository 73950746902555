#main .vrtx-social-list  {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, 47px);

  li {
    margin: 0;
    &:before {
      display: none;
    }
  }
  a {
    text-indent: -9999px;
    overflow: hidden;
    width: 47px;
    height: 47px;
    display: block;
    background: url("@{imagePath}social/mail.svg") no-repeat 50% 50%;
    background-size: 100% auto;
    margin: 0;

    &.facebook    { background-image: url("@{imagePath}social/facebook.svg");    }
    &.twitter     { background-image: url("@{imagePath}social/x.svg");           }
    &.x           { background-image: url("@{imagePath}social/x.svg");           }
    &.youtube     { background-image: url("@{imagePath}social/youtube.svg");     }
    &.linkedin    { background-image: url("@{imagePath}social/linkedin.svg");    }
    &.instagram   { background-image: url("@{imagePath}social/instagram.svg");   }
    &.tripadvisor { background-image: url('@{imagePath}social/tripadvisor.svg'); }
    &.snapchat    { background-image: url('@{imagePath}social/snapchat.svg');    }
    &.pinterest   { background-image: url('@{imagePath}social/pinterest.svg');   }
    &.tiktok      { background-image: url('@{imagePath}social/tiktok.svg');      }

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}

#main .vrtx-social-components {
  >*:first-child  {
    margin-left: 0;
  }
  ul {
    padding-left: 0;
  }

  * {
    display: inline-block;
  }

  li {
    display: inline-block;
    margin-left: 0;
    &:before {
      display: none;
    }
  }
  a {
    margin-left: 56px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-decoration: none;
    position: relative;
    padding-left: 57px;
    min-height: 47px;
    padding-top: 3px;

    span {
      font-size: 1.8rem;
      line-height: 2.7rem;
      display: block;
    }

    &:before {
      content: "";
      width: 47px;
      height: 47px;
      display: inline-block;
      background: url("@{imagePath}social/mail.svg") no-repeat 50% 50%;
      background-size: 100% auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.facebook:before    { background-image: url("@{imagePath}social/facebook.svg");    }
    &.twitter:before     { background-image: url("@{imagePath}social/x.svg");           }

    &:hover,
    &:focus {
      &:before {
        opacity: 0.6;
      }
    }
  }
}

@media @responsiveBreakpoint {
  #main .vrtx-social-components {
    .vrtx-share-at-component,
    li {
      display: block;
      margin-bottom: 10px;
    }
    a {
      margin-left: 0;
    }
  }
}
