@import "components/form-elements.less";
@import "components/dropdown.less";
@import 'components/lightbox.less';
@import "components/subfolder-menu.less";
@import "components/banner.less";
@import "components/tiles.less";
@import 'components/flex-items.less';
@import "components/table.less";
@import "components/feeds.less";
@import "components/feeds-easycruit.less";
@import 'components/toggle-content.less';
@import "components/autocomplete.less";
@import "components/images.less";
@import "components/social.less";
@import "components/toc.less";
@import "components/timeline.less";
@import "components/accordions.less";
@import 'components/tabs.less';
@import "components/feedback.less";
@import 'components/audio-video.less';
@import 'components/audio-podcast.less';
@import "components/step-guide.less";
@import 'components/datepicker.less';
@import "components/special.less";
@import 'components/comments.less';
@import 'components/iframe-chatbot.less';
@import 'components/big-project.less';
@import 'components/horizontal-menu.less';
@import 'components/media-wide.less';
@import 'components/open-day.less';
@import 'components/my-studies-price-calculator.less';
@import 'components/nettskjema-cost-code.less';

#MathJax_Message {
  display: none;
}