// Infoboxes, default black

.grid-container.row-all-colored .vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right {
    background: transparent;
    &:not(.vrtx-frontpage-box-picture) {
      .vrtx-box-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right {
    background: @infoBoxDefaultColor;
    .vrtx-box-content {
      padding: 35px 50px;
    }
    .read-more {
      &,
      a {
        .textUnderlineSwitch;
      }
    }
    &:not(.dark-grey-box):not(.grey-box):not(.red-box):not(.blue-box):not(.transparent-box) {
      *,
      a {
        color: #fff;
      }
      .read-more {
        &:before {
          background-image: url("@{imagePath}arrows/arrow-big-white.svg");
        }
      }
      .button,
      .ui-dialog.ui-widget button,
      .vrtx-button:not(form),
      button,
      input[type=button],
      input[type=reset],
      input[type=submit] {
        border: 1px solid #fff;
        text-decoration: none;
        &:after {
          background-image: url("@{imagePath}arrows/arrow-big-white.svg");
        }
        &:hover,
        &:focus {
          background: rgba(80, 85, 88, 1);
        }
      }
    }
    .read-more {
      &:before {
        background-image: url("@{imagePath}arrows/arrow-big.svg");
      }
    }
  }

  &.vrtx-frontpage-box-picture {
    .vrtx-frontpage-box-picture {
      a {
        display: block;
      }
      margin: 0 0 20px 0;
      max-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &.img-special-left,
    &.img-special-right {
      display: grid;
      &::after {
        display: none !important; // remove float clearing
      }
      .vrtx-frontpage-box-picture {
        display: grid; // control img height (or safari stretches to viewport)
        margin: 0;
        img {
          display: block;
          max-width: none;
          aspect-ratio: 4/3;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .vrtx-box-content {
        align-self: center;
        padding: 50px 50px 60px 50px;

        > h2:first-child {
          margin-bottom: 40px;
        }
      }
    }
    &.img-special-left {
      grid-template-columns: 2fr 3fr;
      &.transparent-box {
        .vrtx-box-content {
          padding-right: 0;
        }
      }
    }
    &.img-special-right {
      grid-template-columns: 3fr 2fr;
      .vrtx-frontpage-box-picture {
        order: 2;
      }
      &.transparent-box {
        .vrtx-box-content {
          padding-left: 0;
        }
      }
    }
  }
}

#main .row-all-colored {
  .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right {
      .vrtx-frontpage-box-picture,
      .vrtx-box-content {
        margin-top: -@verticalGap;
        margin-bottom: -@verticalGap;
      }
    }
  }
}

#main .vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right {
    h2 {
      &,
      a.read-more {
        font-size: 4rem;
        line-height: 4.9rem;
      }
      &:only-child {
        margin: 0;
      }
    }
  }
}

@media @responsiveBreakpoint {

  #main #right-main,
  #main #total-main {
    .vrtx-frontpage-box {
      &.img-special-left,
      &.img-special-right {
        .vrtx-box-content {
          padding: 20px 15px 30px 15px;
        }
      }
      &.vrtx-frontpage-box-picture {
        &.img-special-left,
        &.img-special-right {
          padding: 0 0 40px 0;
          display: block;
          > *:not(.vrtx-frontpage-box-picture) {
            padding-left: 15px;
            padding-right: 15px;
          }
          .vrtx-box-content {
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .vrtx-frontpage-box-picture {
            margin: 0 0 20px 0;
          }
        }
      }
    }
    .row-all-colored {
      .vrtx-frontpage-box {
        &.vrtx-frontpage-box-picture {
          &.img-special-left,
          &.img-special-right {
            .vrtx-frontpage-box-picture {
              margin-top: -@verticalGap;
            }
            .vrtx-box-content {
              margin: 0 0 -40px 0;
            }
          }
        }
      }
    }
  }
}
