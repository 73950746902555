#vrtx-custom-video-listing {
  .vrtx-listing-filters-results {
    display: flex;
    gap: 0;
    margin-left: -195px;
  }

  #left-main {
    display: none;
  }

  #ils-video-tos {
    max-width: 900px;

    .button-large {
      width: auto;
      &:hover,
      &:focus {
        padding-left: 20px;
      }
      padding-right: 20px;
      &:after {
        display: none;
      }
    }
    .button-large + .button-large {
      margin-left: 20px;
    }
  }

  .ui-accordion.ui-widget {
    margin: 0;

    .ui-accordion-content {
      padding-bottom: 0;
    }
  }

  .toggle-more {
    margin: 0 0 20px 0;

    h3 {
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: bold;
      &:before {
        display: none;
      }
      &:after {
        content: "+";
        font-weight: normal;
        color: @textColor;
        display: block;
        width: 0.6em;
        height: 0.3em;
        position: absolute;
        right: 0;
        top: 1.05rem;
        line-height: 0;
      }

      a {
        margin-left: 0;
        padding-left: 0;
        padding-right: 20px;
        width: 100%;
        text-decoration: none;
      }
    }

    .toggle-more-content {
      padding: 0;
      margin: 0;
      transition: none;
    }

    &.toggle-more-active {
      h3:after {
        content: "–";
      }
    }
  }

  .vrtx-listing-filter-responsive-buttons,
  .vrtx-listing-filter-responsive-show {
    display: none;
  }
  
  #vrtx-listing-filters-selected-wrapper,
  .vrtx-filter-category-selected {
    &:not(:has(.vrtx-filter-category-selected-value)) {
      display: none;
    }
  }

  #vrtx-listing-filters-selected {
    margin: 20px 0;
    display: flex;
    gap: 15px 20px;
    flex-wrap: wrap;
  }

  .vrtx-filter-category-selected-value {
    width: auto;
    white-space: nowrap;
    
    font-size: 1.6rem;
    line-height: 2.4rem;

    text-decoration: none;
    padding: 6px 11px 6px 32px;
    display: inline-block;
    color: var(--textColor);
    border: 1px solid #000;
    background: transparent url(/vrtx/dist/resources/uio2/css/images/form-elements/x.svg) no-repeat left 0.3em center;
  }

  #vrtx-listing-filters-selected-wrapper {
    p {
      margin-bottom: 40px;
    }
    .button-large {
      padding-right: 20px;
      width: auto;
      font-size: 1.8rem;
      line-height: 2.7rem;
      padding: 6px 15px;

      &:hover,
      &:focus {
        padding-left: 15px;
      }

      &:after {
        display: none;
      }
    }
  }

  .vrtx-filter-category-selected-title {
    font-weight: bold;
  }

  .vrtx-listing-results-pagination {
    max-width: 1200px;
  }

  .vrtx-listing-filters-collapsed {
    width: 170px;
    margin-right: 25px;
    position: sticky;
    top: 0;
    align-self: flex-start;
    padding-top: 10px;
    margin-top: -10px;
    max-height: ~"calc(100vh - var(--topPos))";
    overflow: hidden;
    overflow-y: auto;
    flex-shrink: 0;

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .vrtx-listing-filters-section {
    &:first-child {
      >*:first-child {
        margin-top: 0;
      }
    }
  }

  #main .vrtx-listing-filter-parameter {
    margin: 6px 0;

    a { 
      width: ~"calc(100% - 30px)";
    }
  }

  #vrtx-listing-filter-hits {
    margin: 0 0 20px 0;
    // font-size: 1.8rem;
    // line-height: 2.7rem;

    font-size: 2.3rem;
    line-height: 3.1rem;
  }

  .vrtx-listing-video-results {
    ul {
      display: grid;
      grid-template-columns: minmax(100px, 380px) minmax(100px, 380px) minmax(100px, 380px);
      gap: 30px;
      margin: 0;

      img {
        display: block;
      }

      .vrtx-listing-video-title {
        font-size: 2.6rem;
        line-height: 3.1rem;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .vrtx-listing-video-thumbnail-duration {
        position: relative;
        height: 214px;
        width: 100%;
        background: black;
        display: block;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .vrtx-listing-video-duration {
          position: absolute;
          bottom: 10px;
          right: 10px;

          font-size: 1.6rem;
          color: #fff;
          padding: 3px 10px;
          background-color: var(--colorNeutralTint20);
        }
      }

      .vrtx-listing-video-published-date {
        font-size: 1.7rem;
        line-height: 2.8rem;
        color: var(--publishedDateGrey);
      }

      .vrtx-listing-video-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
      }

      .vrtx-listing-video-description {
        max-height: 80px;
        width: 100%;
        overflow: hidden;
        position: relative;

        >*:first-child {
          margin-top: 0;
        }
        >*:last-child {
          margin-bottom: 0;
        }
      }

      li {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;

        &:before {
          display: none;
        }
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-custom-video-listing {
    .vrtx-listing-filters-results {
      margin-left: 0;
    }
    .vrtx-listing-video-results {
      ul {
        grid-template-columns: minmax(100px, 380px) minmax(100px, 380px);
      }
    }
  }

  #vrtx-custom-video-listing {
    .vrtx-listing-filters-results {
      display: block;
    }

    .vrtx-listing-filters-sections {
      display: none;
      position: fixed;
      overflow: auto;
      bottom: 80px;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;

      padding: 20px 15px 30px 15px;
    }

    // Hide everything excepts filters in responsive mode / "fullscreen"
    &.visible-responsive-filters {
      #left-main,
      & > *:not(#main),
      #total-main > *:not(.vrtx-listing-filters-results),
      .vrtx-listing-filters-results >*:not(.vrtx-listing-filters-collapsed),
      .vrtx-listing-filters-collapsed >*:not(.vrtx-listing-filters-sections):not(.vrtx-listing-filter-responsive-buttons) {
        display: none;
      }
    }
    &.visible-responsive-filters {
      .vrtx-listing-filters-sections {
        display: block;
      }
      .vrtx-listing-filter-responsive-buttons {
        display: block;
      }
    }

    .vrtx-listing-filter-responsive-show {
      display: inline-block;
    }
    .vrtx-listing-filter-responsive-buttons {
      gap: 20px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 20px 15px;
      z-index: 1;

      button {
        width: auto;
        padding: 12px 42px;
        font-size: 1.8rem;
        line-height: 2.7rem;

        &:first-child {
          margin-right: 25px;
        }
      }

      &:before {
        content: "";
        z-index: 0;
        background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.1) 100%);
        height: 50px;
        width: ~"calc(100% + 30px)";
        display: block;
        position: absolute;
        top: -50px;
        margin-left: -15px;
      }
    }

    .toggle-more .toggle-more-content {
      height: auto !important;
    }

    .vrtx-listing-filters-collapsed {
      width: 100%;
      position: static;
      margin-right: 0;
    }

    .vrtx-listing-results-pagination {
      margin-top: 40px;
    }
  }
}

@media @responsiveBreakpoint {

  #vrtx-custom-video-listing {
    .lightbox .lightbox-content {
      padding: 30px;
    }

    #ils-video-tos {
      max-width: 900px;
  
      .button-large + .button-large {
        margin-left: 0px;
        margin-top: 20px;
      }
    }

    .vrtx-listing-video-results {
      ul {
        display: block;
        grid-template-columns: auto;

        .vrtx-listing-video-thumbnail-duration {
          height: auto;
          width: 100%;
        }

        li {
          margin-bottom: 20px;
        }
      }
    }
  }
}