/*
Styles used to get a full-width image in frontpage row.
Similar approach as background-video, but uses traditional box-picture and box-content.
Video included as a future ambition.
*/



@screen-large: ~"min-width: 1071px";

.grid-container {
  &.fullwidth-media-preserve-top {
    img,
    video {
      object-position: top;
    }
  }
  &.fullwidth-media-preserve-bottom {
    img,
    video {
      object-position: bottom;
    }
  }
}

.grid-container.fullwidth-image {
  background: none;
  padding: 0;
  #vrtx-frontpage #main .row {
    position: relative;
  }
  .vrtx-frontpage-box-picture {
    margin: 0;
  }
  img,
  video,
  .vrtx-frontpage-box {
    @media (@screen-large) {
      min-height: 500px;
    }
  }
  img,
  video {
    width: 100%;
    object-fit: cover;
    @media (@screen-large) {
      height: 85vh;
    }
    @media @desktopSmallBreakpoint {
      height: 70vh;
    }
  }
  .vrtx-frontpage-box {
    @media (@screen-large) {
      background-color: #eee;
    }
  }
  .vrtx-box-content {
    background-color: rgba(255,255,255, 0.9);
    padding: 40px 30px 40px 30px; // needs tweaking for screen sizes below large
    @media (@screen-large) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: 500px;
    }
    @media (min-width: 1200px) {
      left: 54%;
    }
  }
}


/*
Styles used to get a full-width video in a frontpage row.
Similar approach as background-video,
but uses a manually added div for the text-box on top of the video.
Uses ${media:background-video} component pasted into source.
Component includes a pause-button which needs imported JS:
vrtx/groups/all/src/resources/js/media-wide.js => MediaWide.backgroundVideo()
*/

.grid-container.fullwidth-video {
  background: none;
  padding: 0;
  
  .row {
    position: relative;
  }
  video,
  .vrtx-frontpage-box {
    @media (@screen-large) {
      min-height: 500px;
    }
  }
  video {
    width: 100%;
    height: auto;
    object-fit: cover;
    @media (@screen-large) {
      height: 85vh;
    }
    @media @desktopSmallBreakpoint {
      height: 70vh;
    }
  }
  .vrtx-frontpage-box {
    @media (@screen-large) {
      background-color: #eee;
    }
  }
  // text-box div. added manually, with heading and text inside
  .text-box {
    background-color: rgba(255,255,255, 0.9) ;
    padding: 40px 30px 40px 30px; // needs tweaking for screen sizes below large
    @media (@screen-large) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: 500px;
    }
    @media (min-width: 1200px) {
      left: 54%;
    }
    h2 {
      margin-top: 0;
    }
  }
}

// Typography override

#vrtx-frontpage {
  .grid-container.fullwidth-video,
  .grid-container.fullwidth-image {
    h2 {
      font-size: 3.4rem;
      line-height: 4.1rem;
    }
  }
}

#vrtx-frontpage #main {
  .grid-container.fullwidth-video,
  .grid-container.fullwidth-image {
    margin-bottom: 60px;
  }
}

// preview + edit mode: disable vh units

body.vrtx-mode-edit,
body.vrtx-mode-preview {
  .grid-container.fullwidth-image {
    img,
    video {
      height: auto;
    }
  }
  .grid-container.fullwidth-video {
    video {
      height: auto;
    }
  }
}

#vrtx-frontpage #main .grid-container.fullwidth-image .row,
#vrtx-frontpage #main .grid-container.fullwidth-video .row {
  // except edit mode
  &:not(.vrtx-edit-row) {
    padding: 0;
    max-width: none;
  }
}

@media @responsiveBreakpoint {
  #vrtx-frontpage #main .grid-container.page-top-white,
  #vrtx-frontpage #main .grid-container.page-top {
    .vrtx-frontpage-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*
Class used on row for background-video and wide-media, to display row at top of page, under header.
Using negative margin to pull row up under header.
Also possible to use position absolute on header to layer it on top.
This just shows the basic principle. Will need more work. Top-spacing on mobile etc.
Needs imported JS:
vrtx/groups/all/src/resources/js/media-wide.js => MediaWide.pageTopMedia()
*/

// subtract header height for overlap, except when menu is open
body:not(.sidebar-menu-wrapper-visible) {
  .grid-container.page-top-white,
  .grid-container.page-top {
    margin-top: -160px !important;
  }
  @media @desktopSmallBreakpoint {
    .grid-container.page-top-white,
    .grid-container.page-top {
      margin-top: -130px !important;
    }
  }
  @media @responsiveBreakpoint {
    .grid-container.page-top-white,
    .grid-container.page-top {
      margin-top: -145px !important;
    }
  }
}

// Menu can be tight up to media in top
body.has-page-top-row-white,
body.has-page-top-row {
  .sidebar-menu {
    margin-bottom: 0px;
  }
}

// has-page-top-row. body class
// added by js if detecting row page-top class
body.has-page-top-row-white:not(.sidebar-menu-wrapper-visible),
body.has-page-top-row:not(.sidebar-menu-wrapper-visible) {
  // breadcrumbs and h1 not supported with this layout
  // hidden except for screen-reader
  #breadcrumbs,
  h1 {
    position: absolute !important;
    left: -100000px;
  }
  // add backgrounds to header buttons
  .sidebar-menu-toggle,
  #head-wrapper #head #header-language {
    background-color: rgba(255,255,255, 0.9) ;
  }

  @media @desktopSmallBreakpoint {
    #head-wrapper #head #header-language {
      height: auto;
    }
  }
}

// edit-mode

body.vrtx-mode-edit {
  .grid-container.page-top-white,
  .grid-container.page-top {
    // don't overlap header
    margin-top: 0 !important;
  }
  &.has-page-top-row-white,
  &.has-page-top-row {
    h1 {
      position: static !important; // revert from absolute
    }
  }
}

// video component
.vrtx-background-video {
  position: relative;

  video {
    max-width: 100%;
  }

  // pause-button
  .pause-video {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 80px;
    height: 80px;
    background: rgba(255,255,255, 0.9) url("/vrtx/dist/resources/images/video/pause.svg") no-repeat center;
    background-size: 30px;
    padding: 0;
    border: none;
    border-radius: 0;
    opacity: 0; // invisible unless hover/focus
    transition: opacity 1s;
    &::after {
      display: none;
    }
    &.paused {
      background-image: url("/vrtx/dist/resources/images/video/play.svg");
    }
  }
  // hover on container or focus on button -> show button
  &:hover .pause-video,
  .pause-video:focus-visible {
    opacity: 1;
  }
}


/*
Styles used to get full-screen image or video on article-type pages.
Sticky effect if caption present. Js-detected.
Solution is not production-ready.
Requires editor-functionality for adding image/video of this type.
*/

/*
Todo for UiO/Museums
- Replace manual breakpoints with suitable uio variables.
- Check that calculation of left pos at viewport 0 works with uio framework.
*/

video {
  max-width: 100%;
}

// excluding admin preview due to several styles not working well there,
// such as vh units and fixed positioning

body:not(.vrtx-mode-preview) {

  &#vrtx-structured-article,
  &#vrtx-structured-event {

    // prevent 100vw images from adding horizontal scrollbar on windows
    overflow-x: hidden;

    .vrtx-article-body .media-fullscreen {
      img,
      video {
        @media (min-width: 1071px) {
          object-fit: cover;
          max-width: none;
          width: 100vw;
          height: 100vh;
        }

        // calculate left pos at viewport 0

        --main-indent: -220px;
        --article-body-indent: -155px; // calc value seems to be constant within breakpoints used here
        --viewport-indent: calc((100vw - 690px)/-2);

        @media (max-width: 1179px) and (min-width: 1071px) {
          margin-left: var(--main-indent);
        }
        @media (min-width: 1180px) and (max-width: 1454px) {
          margin-left: calc(var(--main-indent) + var(--article-body-indent));
        }
        @media (min-width: 1455px) {
          margin-left: calc(var(--viewport-indent));
        }
      }

      // with caption and sticky effect
      @media (min-width: 1071px) {
        &.has-caption {
          img,
          video {
            z-index: 1;
          }
          figure {
            position: relative;
            z-index: 1;

            // double viewport height for sticky scroll
            height: 200vh;

            // video-component. revert from relative to follow sticky behaviour
            .vrtx-background-video {
              position: static;
            }
          }
          figcaption {
            position: absolute;
            z-index: 2;
            left: 20vw;
            top: 50%;
            background: white;
            padding: 40px;
            font-size: inherit;
            color: inherit;
            line-height: inherit;
            width: 500px;
          }
          &.media-fullscreen-caption-left {
            figcaption {
              left: ~"calc(-20vw + 200px)";
            }
          }
          // js-detected on scroll
          &.sticky {
            img,
            video {
              position: fixed;
              top: 0;
              z-index: 1;
            }
            .pause-video {
              position: fixed;
              left: auto;
              z-index: 1;
            }
          }
        }

        // js-detected on scroll
        &.scrolled-past {
          img,
          video {
            position: absolute;
            bottom: 0;
          }
        }
        &.scrolled-past {
          figcaption {
            opacity: 0;
          }
        }

        img,
        video,
        figcaption {
          transition: opacity 1s;
        }
        &.has-caption + .has-caption {
          // Subtract media/screen height and margin between items.
          margin-top: calc(-100vh - 40px);
          img,
          video {
            opacity: 0;
          }
        }
        // When row of .has-caption is in viewport, this class is added on each one in row (removed if not)
        // Keeps the media inside row still/fixed when scrolling (only figcaption is moving) to get
        // smooth fade between media
        &.sticky-media {
          &.sticky-media-active-paused-scrolling {
            z-index: 2;
            position: relative;
          }
          img,
          video {
            position: fixed;
            top: 0;
            z-index: 1;
          }
        }
        &.scrolled-past + .has-caption {
          img,
          video {
            opacity: 1;
          }
        }
      }
    }
  }
}