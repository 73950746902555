// TODO: need a little cleanup

#head-wrapper {
  padding-top: 25px;
  position: relative;
  z-index: 5;

  #head {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto auto;
  }
  .sidebar-menu-toggle {
    align-self: start;
  }
}

#head-context-wrapper {
  display: none;
}

.uio-app-name {
  padding-left: 195px;
  margin-bottom: 60px;
  padding-right: 60px;
}

.uio-app-name a {
  .textUnderlineSwitch;
}

.uio-acronym,
.uio-host {
  font-size: 2.8rem;
}
.uio-host {
  line-height: 3.6rem;
  position: relative;
}
.uio-acronym {
  text-indent: -10000px;
  overflow: hidden;
  height: 30px;
  width: 152px;
  background: url("@{imagePath}header/uio-logo-no.svg") no-repeat 0 0;
  background-size: auto 100%;
  position: relative;
  left: -195px;
}
body.en .uio-acronym {
  background-image: url("@{imagePath}header/uio-logo-en.svg");
  background-size: auto 100%;
}
.uio-acronym,
.uio-faculty {
  display: inline-block;
}
a.uio-faculty {
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 4px 12px;
  background: #ebedfc;
  color: #000;
  margin-left: -156px;
  position: relative;
  top: -10px;
}

body.faculty .uio-host {
  margin-left: -152px;
  position: relative;
  top: -1px;
}

body.faculty .uio-host {
  top: -9px;
}

body:not(.faculty) {
  .uio-host {
    display: block;
    margin-top: 5px;
    font-size: 3rem;
    line-height: 3.4rem;
  }
}

/*
.the-frontpage {
  .uio-host {
    font-size: 4.2rem;
    line-height: 4.9rem;
  }
  a.uio-faculty {
    margin-left: -156px;
  }
  &.faculty .uio-host {
    margin-left: -156px;
  }
  &:not(.faculty) .uio-host {
    display: block;
    margin-top: 5px;
    font-size: 4.2rem;
    line-height: 4.4rem;
  }
}
*/

body.www\.uio\.no:not(.header-context) {
  .uio-acronym,
  .uio-faculty {
    display: none;
  }
  .uio-app-name {
    max-width: @contentWidth;
    padding-left: 0;
  }
  &.the-frontpage.faculty .uio-host,
  &.faculty .uio-host {
    margin-left: 0;
  }
  .uio-host {
    text-transform: uppercase;
    overflow: hidden;
    text-indent: -10000px;
    display: inline-block;
    width: 254px;
    height: 50px;
    background: url("@{imagePath}header/uio-logo-no.svg") no-repeat 0 0;
    background-size: 100% auto;
    top: 0;
    z-index: 3;
  }
  &.has-slogan {
    .uio-host {
      width: 300px;
      height: 59px;
    }
  }
  &.en {
    .uio-host {
      background-image: url("@{imagePath}header/uio-logo-en.svg");
      background-size: auto 100%;
    }
  }
}

// Hide faculty, on e.g. cross-university centre Hylleraas
body.header-hide-faculty.institute:not(.faculty) {
  .uio-acronym,
  .uio-faculty {
    display: none;
  }
  .uio-host {
    margin-left: 0;
  }
  &.sidebar-menu-wrapper-visible.no {
    .uio-app-name {
      padding-left: 204px;
    }
  }
  &:not(.sidebar-menu-wrapper-visible) {
    .uio-host {
      display: inline;
      margin-left: 0;
      top: 7px;
    }
  }
  &:not(.the-frontpage):not(.sidebar-menu-wrapper-visible) {
    .uio-host {
      top: 10px;
    }
  }
}

// Head login/logout
#head-admin-menu-wrapper {
  background: #f4f6ff;
  height: 44px;
  font-size: 1.4rem;
  width: 100%;

  #head-admin-menu {
    padding-top: 8px;
    text-align: right;

    .vrtx-login-manage-wrapper {
      text-align: left;
    }
  }

  .vrtx-login-manage-logout {
    margin-left: 40px;
  }

  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: url("@{imagePath}header/dropdown-toggle-black.png") no-repeat center right;
    padding-right: 18px;
  }

  .vrtx-dropdown-wrapper {
    z-index: 6;

    a {
      .textUnderlineSwitch;
    }
    .vrtx-dropdown-last {
      display: none;
    }
  }
}

@media @desktopSmallBreakpoint {

  #head-wrapper {
    padding: 30px 15px 25px 15px;
    margin: 0;
  }

  .uio-app-name {
    margin-bottom: 0;
    padding-right: 0;
    min-width: ~"calc(100% + 175px)";
  }

  body.www\.uio\.no:not(.header-context) {
    &:not(.has-slogan),
    &.has-slogan {
      &.en {
        .uio-host {
          width: 203px;
          background-size: auto 100%;
        }
      }
      .uio-host {
        width: 203px;
        height: 42px;
        margin-top: 0;
      }
      .uio-app-name {
        max-width: 100%;
        min-width: 0;
      }
      #head-wrapper {
        #head {
          padding-left: 0;
        }
      }
    }
  }

  #head-wrapper #head {
    padding: 0;
  }

  body.faculty:not(.the-frontpage) .uio-host,
  body.faculty .uio-host,
  .uio-acronym,
  body.institute .uio-acronym,
  .the-frontpage.institute .uio-acronym {
    top: 0;
    left: 0;
  }

  body.faculty:not(.institute):not(.has-slogan) {
    &.the-frontpage {
      .uio-app-name {
        margin-top: 0px;
      }
    }
  }

  a.uio-faculty,
  body.faculty .uio-host,
  .the-frontpage a.uio-faculty,
  .the-frontpage.faculty .uio-host {
    margin-left: 0;
  }

  .uio-app-name {
    padding-left: 0;
  }

  .uio-acronym {
    display: block;
  }

  // Faculty

  .uio-host {
    font-size: 2.3rem;
    line-height: 3.2rem;
    margin-top: 20px;
    display: inline-block;
  }
  body:not(.faculty) .uio-host {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }

  // Institute

  a.uio-faculty {
    margin-top: 32px;
  }

  // Hide faculty, on e.g. cross-university centre Hylleraas
  body.header-hide-faculty.institute:not(.faculty) {
    #main #left-main {
      top: 30px !important; // override JS
    }
    .uio-host {
      display: inline-block;
      margin-left: 0;
      margin-top: 0;
    }
    &:not(.sidebar-menu-wrapper-visible) {
      .uio-host {
        display: inline-block;
        margin-left: 0;
        top: 0;
        padding-right: 60px;
      }
    }
    &.sidebar-menu-wrapper-visible {
      .uio-host {
        padding-right: 205px;
      }
      &.no {
        .uio-app-name {
          padding-left: 0;
        }
        .uio-host {
          padding-right: 185px;
        }
      }
    }
    &:not(.sidebar-menu-wrapper-visible) {
      .uio-host {
        top: 0;
      }
    }
  }
}

#head-wrapper-back-to-uio {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #111;
  a {
    width: 320px;
    display: block;
    height: 28px;
    background: url('@{imagePath}header/uio-logo-white-no.svg') 0 0 no-repeat;
    background-size: auto 100%;
  }
}
body.en #head-wrapper-back-to-uio a {
  background-image: url('@{imagePath}header/uio-logo-white-en.svg')
}

@media @desktopSmallOnlyBreakpoint {
  #head-wrapper {
    padding-left: 25px;
    padding-right: 20px;
  }
  .uio-app-name {
    margin-bottom: 15px;
  }
}

@media @responsiveBreakpoint {
  body.www\.uio\.no:not(.header-context).sidebar-menu-wrapper-visible {
    &:not(.has-slogan),
    &.has-slogan {
      &.en {
        .uio-host {
          width: 152px;
          background-size: auto 100%;
        }
      }
      .uio-host {
        height: 30px;
        width: 152px;
      }
    }
  }

  body.www\.uio\.no:not(.header-context):not(.has-slogan) .uio-host {
    margin-bottom: 0;
  }
  .uio-app-name {
    min-width: ~"calc(100%)";
  }
  .sidebar-menu-wrapper-visible .uio-app-name {
    min-width: ~"calc(100% + 130px)";
  }
}
